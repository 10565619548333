const validate = require('@weo-edu/validate')
const Schema = require('@weo-edu/schema')

const updateActivity = Schema()
  .prop('integrationId', Schema('string'))
  .prop('integrationUserId', Schema('string'))
  .prop('lessonId', Schema('string'))
  .prop('moduleId', Schema('string'))
  .prop('activityId', Schema('string'))
  .prop('completed', Schema('boolean'))
  .required([
    'integrationId',
    'integrationUserId',
    'lessonId',
    'moduleId',
    'activityId',
    'completed'
  ])

exports.updateActivity = validate(updateActivity)
