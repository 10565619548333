import getProp from '@f/get-prop'

const studentGrades = [
  {
    label: 'Kindergarten',
    value: 'Kindergarten',
    id: 0
  },
  {
    label: '1st',
    value: '1',
    id: 1
  },
  {
    label: '2nd',
    value: '2',
    id: 2
  },
  {
    label: '3rd',
    value: '3',
    id: 3
  },
  {
    label: '4th',
    value: '4',
    id: 4
  },
  {
    label: '5th',
    value: '5',
    id: 5
  },
  {
    label: '6th',
    value: '6',
    id: 6
  },
  {
    label: '7th',
    value: '7',
    id: 7
  },
  {
    label: '8th',
    value: '8',
    id: 8
  },
  {
    label: '9th',
    value: '9',
    id: 9
  },
  {
    label: '10th',
    value: '10',
    id: 10
  },
  {
    label: '11th',
    value: '11',
    id: 11
  },
  {
    label: '12th',
    value: '12',
    id: 12
  },
  {
    label: 'Higher Education',
    value: '13',
    id: 13
  }
]

const miscGrades = [
  {
    label: 'Other',
    value: 'Other',
    id: -2
  },
  {
    label: 'Pre Kindergarten',
    value: 'PreKindergarten',
    id: -1
  }
]
const pdGrade = [
  {
    label: 'Professional Development',
    value: 'ProfessionalDevelopment',
    id: 14
  }
]

const grades = [...miscGrades, ...studentGrades, ...pdGrade]

const activityTags = [
  {
    label: 'Sequencing',
    value: -1,
    id: -1
  },
  {
    label: 'Loops',
    value: 0,
    id: 0
  },
  {
    label: 'Conditionals',
    value: 1,
    id: 1
  },
  {
    label: 'Variables',
    value: 2,
    id: 2
  },
  {
    label: 'Functions',
    value: 3,
    id: 3
  },
  {
    label: 'Parameters',
    value: 4,
    id: 4
  },
  {
    label: 'Lists',
    value: 5,
    id: 5
  },
  {
    label: 'Expressions',
    value: 6,
    id: 6
  },
  {
    label: 'Noticing',
    value: 7,
    id: 7
  },
  {
    label: 'Inventorying',
    value: 8,
    id: 8
  },
  {
    label: 'Tinkering',
    value: 9,
    id: 9
  },
  {
    label: 'Debugging',
    value: 10,
    id: 10
  },
  {
    label: 'Hill Climbing',
    value: 11,
    id: 11
  },
  {
    label: 'Break It Down',
    value: 12,
    id: 12
  },
  {
    label: 'Decomposition',
    value: 13,
    id: 13
  },
  {
    label: 'Backward Planning',
    value: 14,
    id: 14
  },
  {
    label: 'Tool Utilization',
    value: 15,
    id: 15
  },
  {
    label: 'Abstracting',
    value: 16,
    id: 16
  },
  {
    label: 'Case Analysis',
    value: 17,
    id: 17
  },
  {
    label: 'Rubber Ducking',
    value: 18,
    id: 18
  },
  {
    label: 'Refactoring',
    value: 19,
    id: 19
  },
  {
    label: 'Progress Checking',
    value: 20,
    id: 20
  },
  {
    label: 'Flow Charting',
    value: 21,
    id: 21
  },
  {
    label: 'Pseudocoding',
    value: 22,
    id: 22
  },
  {
    label: 'Commenting',
    value: 23,
    id: 23
  },
  {
    label: 'Pattern Recognition',
    value: 24,
    id: 24
  },
  {
    label: 'Problem Mapping',
    value: 25,
    id: 25
  },
  {
    label: 'Predict and Hypothesize',
    value: 26,
    id: 26
  }
]

const tags = [
  {
    label: 'PixelBots',
    id: 'f8624290-78ab-11e8-8d23-9b54f4ddd993',
    slug: 'pixelbots'
  },
  {
    label: 'Scratch',
    id: 'f8624291-78ab-11e8-8d23-9b54f4ddd993',
    slug: 'scratch'
  },
  { label: 'p5', id: 'f8624292-78ab-11e8-8d23-9b54f4ddd993', slug: 'p5' },
  {
    label: 'Block-based code',
    id: 'f8624293-78ab-11e8-8d23-9b54f4ddd993',
    slug: 'block-based-code'
  },
  {
    label: 'JavaScript',
    id: 'f8624294-78ab-11e8-8d23-9b54f4ddd993',
    slug: 'javascript'
  },
  {
    label: 'Digital literacy',
    id: 'f8624295-78ab-11e8-8d23-9b54f4ddd993',
    slug: 'digital-literacy'
  },
  {
    label: 'Decomposition',
    id: 'f8624296-78ab-11e8-8d23-9b54f4ddd993',
    slug: 'decomposition'
  },
  {
    label: 'Algorithms',
    id: 'f8624297-78ab-11e8-8d23-9b54f4ddd993',
    slug: 'algorithms'
  },
  {
    label: 'Abstraction',
    id: 'f8624298-78ab-11e8-8d23-9b54f4ddd993',
    slug: 'abstraction'
  },
  {
    label: 'Control',
    id: 'f8624299-78ab-11e8-8d23-9b54f4ddd993',
    slug: 'control'
  },
  {
    label: 'Sequencing',
    id: 'f862429a-78ab-11e8-8d23-9b54f4ddd993',
    slug: 'sequencing'
  },
  {
    label: 'Patterns',
    id: 'f862429b-78ab-11e8-8d23-9b54f4ddd993',
    slug: 'patterns'
  },
  {
    label: 'Arguments',
    id: 'f862429c-78ab-11e8-8d23-9b54f4ddd993',
    slug: 'arguments'
  },
  { label: 'Loops', id: 'f862429d-78ab-11e8-8d23-9b54f4ddd993', slug: 'loops' },
  {
    label: 'Pixel Art',
    id: 'f862429e-78ab-11e8-8d23-9b54f4ddd993',
    slug: 'pixel-art'
  },
  {
    label: 'Functions',
    id: 'f862429f-78ab-11e8-8d23-9b54f4ddd993',
    slug: 'functions'
  },
  {
    label: 'Refactoring',
    id: 'f86242a0-78ab-11e8-8d23-9b54f4ddd993',
    slug: 'refactoring'
  },
  {
    label: 'Conditionals',
    id: 'f86242a1-78ab-11e8-8d23-9b54f4ddd993',
    slug: 'conditionals'
  },
  {
    label: 'User Experience',
    id: 'f86242a2-78ab-11e8-8d23-9b54f4ddd993',
    slug: 'user-experience'
  },
  {
    label: 'Game Design',
    id: 'f86242a3-78ab-11e8-8d23-9b54f4ddd993',
    slug: 'game-design'
  },
  {
    label: 'Parameters',
    id: 'f86242a4-78ab-11e8-8d23-9b54f4ddd993',
    slug: 'parameters'
  },
  {
    label: 'Events',
    id: 'f86242a5-78ab-11e8-8d23-9b54f4ddd993',
    slug: 'events'
  },
  {
    label: 'Operators',
    id: 'f86242a6-78ab-11e8-8d23-9b54f4ddd993',
    slug: 'operators'
  }
]

const base = [1, 2, 3, 4, 5]
const mods = ['-', '', '+']
const difficulty = base.reduce((acc, val, i) => {
  return acc.concat(
    mods.map((mod, j) => {
      return {
        label: (val + mod).trim(),
        value: mod
          ? mod === '-'
            ? Math.round((val - 0.3) * 10) / 10
            : Math.round((val + 0.3) * 10) / 10
          : val,
        key: i * mods.length + j
      }
    })
  )
}, [])

const levels = [
  {
    label: 'Level A',
    value: 0,
    id: 0
  },
  {
    label: 'Level B',
    value: 1,
    id: 1
  },
  {
    label: 'Level C',
    value: 2,
    id: 2
  },
  {
    label: 'Level D',
    value: 3,
    id: 3
  },
  {
    label: 'Level E',
    value: 4,
    id: 4
  }
]

const timeUnits = ['Minutes', 'Hours', 'Days', 'Weeks', 'Months']

/**
 * objects can have an icon or iconComponentKey property, the icon property gets tied to antd icon types
 * and iconComponentKey gets mapped to a component defined in ACTIVITY_ICON_COMPONENTS in ActivityIcon.js
 */
const activityTypes = [
  {
    label: 'Practice',
    icon: 'form',
    value: 'practice',
    id: 'practice',
    progress: true
  },
  {
    label: 'Bonus',
    icon: 'star-o',
    value: 'bonus',
    id: 'bonus'
  },
  {
    label: 'Media',
    icon: 'video-camera',
    value: 'media',
    id: 'media',
    progress: true
  },
  {
    label: 'Level Up',
    icon: 'thunderbolt',
    value: 'levelUp',
    id: 'levelUp',
    progress: true
  },
  {
    label: 'Review',
    icon: 'book',
    value: 'review',
    id: 'review',
    progress: true
  },
  {
    label: 'Extension',
    icon: 'file-add',
    value: 'extension',
    id: 'extension'
  },
  {
    label: 'Project',
    icon: 'rocket',
    value: 'project',
    id: 'project',
    progress: true
  },
  {
    label: 'Quiz / Survey',
    icon: 'profile',
    value: 'quiz',
    id: 'quiz',
    progress: true
  },
  {
    label: 'Puzzle',
    iconComponentKey: 'PuzzleIcon',
    value: 'puzzle',
    id: 'puzzle',
    progress: true
  },
  {
    label: 'Multiple Choice',
    icon: 'form',
    value: 'multipleChoice',
    id: 'multipleChoice',
    progress: true
  },
]

const followUps = {
  positive: [
    {
      label: 'Why do you like coding?',
      id: 'followUpPrompt'
    },
    {
      label: "It's fun",
      id: 'followUpFun'
    },
    {
      label: "It's easy",
      id: 'followUpEasy'
    },
    {
      label: "It's challenging",
      id: 'followUpChallenging'
    },
    {
      label: "I'm good at it",
      id: 'followUpGood'
    },
    {
      label: 'Other',
      id: 'followUpOther'
    }
  ],
  negative: [
    {
      label: "Why don't you like coding?",
      id: 'followUpPrompt'
    },
    {
      label: "It's not fun",
      id: 'followUpFun'
    },
    {
      label: "It's too easy",
      id: 'followUpEasy'
    },
    {
      label: "It's too challenging",
      id: 'followUpChallenging'
    },
    {
      label: "I'm not good at it",
      id: 'followUpGood'
    },
    {
      label: 'Other',
      id: 'followUpOther'
    }
  ]
}

const typeMap = { difficulty, levels, grades, tags, activityTypes, followUps }

const support = [
  {
    label: 'Full',
    value: 'full'
  },
  {
    label: 'Partial',
    value: 'partial'
  },
  {
    label: 'Independent',
    value: 'independent'
  }
]

// Takes an array of grade values and returns a grade range e.g. "K - 2nd"
const gradesToText = function (grades, verbose) {
  const arr = [].concat(grades)
  const max = idsToText('grades', Math.max(...arr))
  const min = idsToText('grades', Math.min(...arr))
  const isNumber = !isNaN(parseInt(max, 10))

  if (max === min) return max + (verbose && isNumber ? ' Grade' : '')
  else return `${min === 'Kindergarten' ? 'K' : min} - ${max}`
}

/**
 * Given a grade value, 
 * returns a short grade string, e.g. "Pre K", "K", "3rd".
 * @function gradeValueToShortText
 * @exports
 * @author Ana Asuncion
 * @param {string} value
 * @returns {string}
 */
const gradeValueToShortText = (value) => {
  let shortGradeText = gradesToText(gradeValueToId(value), true).slice(0, 3)
  if (shortGradeText == 'Pre') shortGradeText = 'Pre K'
  if (shortGradeText == 'Kin') shortGradeText = 'K'
  if (shortGradeText == 'Pro') shortGradeText = 'PD'
  return shortGradeText
}

/**
 * If a grade object in the grades array has the given value,
 * returns the id property of that object.
 * Otherwise, returns undefined.
 * @function gradeValueToId
 * @exports
 * @author Julius Diaz Panoriñgan
 * @param {string} value
 * @returns {number|undefined}
 */
const gradeValueToId = function(value) {
  const grade = grades.find(g => g.value === value)
  return grade && grade.id
}

/**
 * Returns an adjectival form of the grade.
 * @function gradeValueToAdjective
 * @exports
 * @author Julius Diaz Panoriñgan
 * @param {string} grade
 * @returns {string|undefined}
 */
const gradeValueToAdjective = grade => {
  const gradeId = gradeValueToId(grade)
  let gradeStr
  if (gradeId === -1) {
    // special case: "Pre Kindergarten" should be hyphenated
    gradeStr = 'Pre-Kindergarten'
  } else {
    gradeStr = (grades.find(({ value }) => value === grade) || {}).label
  }
  if (gradeId >= 1 && grade <= 12) {
    // ordinal numbers get "-grade" appended
    gradeStr += '-grade'
  }
  return gradeStr
}

const getActivityTypeData = function(val = {}) {
  return getData(val.type) || getData('practice')

  function getData(val) {
    return activityTypes.find(({ value }) => value === val) || {}
  }
}

const idsToText = function (dataType, ids) {
  return []
    .concat(ids)
    .map(id => {
      const item = getProp(dataType, typeMap).find(val => val.id === id) || {}
      return item.label || id
    })
    .join(', ')
}

const adminRoles = [
  'admin',
  'districtAdmin',
  'ninedotsAdmin',
  'ninedotsTeacher'
]

export {
  adminRoles,
  studentGrades,
  gradesToText,
  gradeValueToId,
  gradeValueToAdjective,
  gradeValueToShortText,
  getActivityTypeData,
  difficulty,
  idsToText,
  timeUnits,
  activityTypes,
  followUps,
  activityTags,
  pdGrade,
  support,
  grades,
  levels,
  tags
}
