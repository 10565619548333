const { default: Module, lesson, activity } = require('../module')
const validate = require('@weo-edu/validate')
const Schema = require('@weo-edu/schema')
const {
  passwordType,
  displayName,
  firebaseRef,
  email,
  grade,
  lessonId
} = require('../utils')

const moduleRefObject = Schema()
  .prop(/^.*$/, Module)
  .others(false, 'invalid_keys')

const cleverClass = Schema()
  .prop(
    'data',
    Schema()
      .prop('id', Schema('string'))
      .prop('district', Schema('string'))
      .prop('school', Schema('string'))
      .prop('created', Schema('string'))
      .prop('last_modified', Schema('string'))
      .prop('name', displayName)
      .prop('sis_id', displayName)
      .prop('subject', displayName)
      .prop('students', Schema('array'))
      .prop('teacher', firebaseRef)
      .prop('teachers', Schema('array'))
      .prop('grade', grade)
      .prop('term_id', Schema('string'))
      .prop('section_number', Schema('string'))
      .prop('period', Schema('string'))
  )
  .prop('uri', Schema('string'))

const Class = Schema()
  .prop('id', Schema('string'))
  .prop('displayName', displayName)
  .prop('grade', grade)
  .prop('assignedLesson', lesson)
  .prop('owner', firebaseRef)
  .prop('archived', Schema('boolean'))
  .prop(
    'modules',
    Object.assign({}, moduleRefObject.schema, { minProperties: 2 })
  )
  .prop('district', firebaseRef)
  .prop('school', firebaseRef)
  .prop('teacher', firebaseRef)
  .prop('teachers', Schema('array'))
  .prop('students', Schema('array'))
  .prop('passwordType', passwordType)
  .prop('providers', Schema().prop('clever', cleverClass.schema))
  .others(false)
  .required(['displayName', 'grade', 'school', 'owner'])

const createClass = Schema()
  .prop('displayName', displayName)
  .prop('grade', grade)
  .prop('school', firebaseRef)
  .prop('teacher', firebaseRef)
  .prop('district', firebaseRef)
  .others(false, 'invalid_fields')
  .required(['displayName', 'grade', 'school'], 'missing_required_field')

const addStudent = Schema()
  .prop('class', firebaseRef)
  .prop('student', firebaseRef)
  .others(false, 'invalid_fields')
  .required(['class', 'student'], 'missing_required_field')

const studentObj = Schema()
  .prop('uid', firebaseRef)
  .prop('email', email)
  .required(['uid'], 'missing_required_field')

const addStudents = Schema()
  .prop('class', firebaseRef)
  .prop('students', Schema('array').items(studentObj.schema))
  .others(false, 'invalid_fields')
  .required(['class', 'students'], 'missing_required_field')

const addTeacher = Schema()
  .prop('class', firebaseRef)
  .prop('teacher', firebaseRef)
  .others(false, 'invalid_fields')
  .required(['class'], 'missing_required_field')

const removeTeacher = Schema()
  .prop('class', firebaseRef)
  .prop('teacher', firebaseRef)
  .others(false, 'invalid_fields')
  .required(['class'], 'missing_required_field')

const removeStudent = Schema()
  .prop('class', firebaseRef)
  .prop('student', firebaseRef)
  .others(false, 'invalid_fields')
  .required(['class', 'student'], 'missing_required_field')

const removeStudents = Schema()
  .prop('class', firebaseRef)
  .prop(
    'students',
    Schema('array')
      .items(firebaseRef.schema)
      .min(1)
  )
  .others(false, 'invalid_fields')
  .required(['class', 'students'], 'missing_required_field')

const addModule = Schema()
  .prop('class', firebaseRef)
  .prop('module', firebaseRef)
  .required(['class', 'module'], 'missing_required_field')
  .others(false, 'invalid_fields')

const addCourse = Schema()
  .prop('class', firebaseRef)
  .prop('course', firebaseRef)
  .required(['class', 'course'], 'missing_required_field')
  .others(false, 'invalid_fields')

const removeAssignedModule = Schema()
  .prop('class', firebaseRef)
  .prop('module', firebaseRef)
  .required(['class', 'module'], 'missing_required_field')
  .others(false, 'invalid_fields')

const assignLesson = Schema()
  .prop('class', firebaseRef)
  .prop('lesson', lessonId)
  .prop('module', firebaseRef)
  .prop('student', firebaseRef)
  .required(['class', 'lesson', 'module'])

const setPasswordType = Schema()
  .prop('class', firebaseRef)
  .prop('passwordType', passwordType)
  .required(['class', 'passwordType'])

const disablePassword = Schema().prop('class', firebaseRef)

const isBoySurvey = Schema()
  .prop('class', firebaseRef)
  .required(['class'])

const updateDetails = Schema()
  .prop('class', firebaseRef)
  .prop('displayName', displayName)
  .prop('grade', grade)
  .required(['class', 'displayName', 'grade'])

const getActivityTeacherView = Schema()
  .prop('activity', activity.schema)
  .required(['activity'])

const archiveClass = Schema().prop('class', firebaseRef)

exports.default = Class
exports.setPasswordType = validate(setPasswordType)
exports.disablePassword = validate(disablePassword)
exports.removeStudents = validate(removeStudents)
exports.updateDetails = validate(updateDetails)
exports.removeStudent = validate(removeStudent)
exports.removeTeacher = validate(removeTeacher)
exports.addCourse = validate(addCourse)
exports.assignLesson = validate(assignLesson)
exports.archiveClass = validate(archiveClass)
exports.removeAssignedModule = validate(removeAssignedModule)
exports.addStudents = validate(addStudents)
exports.isBoySurvey = validate(isBoySurvey)
exports.createClass = validate(createClass)
exports.addTeacher = validate(addTeacher)
exports.addStudent = validate(addStudent)
exports.addModule = validate(addModule)
exports.getActivityTeacherView = validate(getActivityTeacherView)
