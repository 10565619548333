const Schema = require('@weo-edu/schema')
const validate = require('@weo-edu/validate')
const {
  firebaseRefObject,
  // termsVersion,
  passwordType,
  firebaseRef,
  displayName,
  // ethnicity,
  password,
  // username,
  support,
  grade,
  email,
  uuid,
  date
} = require('../utils')

const name = Schema()
  .prop('given', displayName)
  .prop('family', displayName)
  .required(['given', 'family'])

// const gender = Schema('string').enum(['male', 'female', 'other'])
// const schools = Schema()
//   .prop(/^[a-zA-Z0-9]{6,}$/, Schema('string').enum(['teacher', 'student']))
//   .others(false)

const stateId = Schema('string', /^[a-zA-Z0-9]$/)

const User = Schema()
  .prop('displayName', displayName)
  .prop('name', name)

  .prop(
    'roles',
    Schema().prop(
      /^[a-zA-Z0-9]{6,}$/,
      Schema('string').enum(['districtAdmin', 'admin', 'teacher', 'student'])
    )
  )
  .required(['displayName', 'avatarUrl', 'name'], 'missing_required_field')

const signUp = Schema()
  .prop('displayName', displayName)
  .prop('name', name)
  .required(['displayName', 'name'], 'missing_required_field')

const createStudent = Schema()
  .prop('school', firebaseRef)
  .prop('grade', grade)
  .prop('email', email)
  .prop('name', name)
  .prop('stateId', stateId)
  .prop('district', firebaseRef)
// .required(['name', 'school', 'grade', 'district'], 'missing_required_field')

const createStudentWithEmail = Schema(createStudent).required(
  ['name', 'school', 'grade', 'district', 'email'],
  'missing_required_field'
)

const createStudentWithStateId = Schema(createStudent).required(
  ['name', 'school', 'grade', 'district', 'stateId'],
  'missing_required_field'
)

const editUser = Schema()
  .prop('id', firebaseRef)
  .prop('name', name)
  .prop('grade', grade)
  .prop('displayName', displayName)
  .prop('support', support)
  .required(['id', 'name'], 'missing_required_field')
  .others(false)

const createStudents = Schema('array').items(createStudent.schema)

const setCurrentSchool = Schema()
  .prop('school', firebaseRef)
  .required(['school'], 'missing_required_field')

const addToSchool = Schema()
  .prop('school', firebaseRef)
  .prop('user', firebaseRef)
  .prop('role', Schema('string').enum(['teacher', 'student']))
  .required(['school'], 'missing_required_field')

const removeFromSchool = Schema()
  .prop('school', firebaseRef)
  .prop('user', firebaseRef)
  .required(['school', 'user'], 'missing_required_field')

const archiveUser = Schema()
  .prop('user', firebaseRef)
  .required(['user'], 'missing_required_field')

const setTermsVersion = Schema()
  .prop('uid', firebaseRef)
  .prop('version', Schema('number'))

const setNav = Schema()
  .prop('uid', firebaseRef)
  .prop('class', firebaseRef)

const assignLesson = Schema()
  .prop('lesson', uuid)
  .prop('class', firebaseRef)
  .prop('teachers', firebaseRefObject)
  .prop('module', firebaseRef)
  .required(['lesson', 'class', 'teachers', 'module'])

const setAssignedLessonIndex = Schema()
  .prop('user', firebaseRef)
  .prop('lesson', uuid)
  .prop('current', Schema('number'))

const signInWithPassword = Schema()
  .prop('user', firebaseRef)
  .prop('password', password)
  .prop('type', passwordType)
  .required(['user', 'password', 'type'])

const setInsecurePassword = Schema()
  .prop('user', firebaseRef)
  .prop('type', passwordType)
  .prop('password', password)
  .required(['user', 'type'])

const setRoles = Schema()
  .prop('user', firebaseRef)
  .prop(
    'roles',
    Schema().prop('admin', Schema('array').items(firebaseRef)),
    Schema().prop('teacher', Schema('array').items(firebaseRef))
  )
  .required(['user', 'roles'])

const inviteUser = Schema()
  .prop('role', Schema('string').enum(['admin', 'teacher']))
  .prop('school', firebaseRef)
  .prop('district', firebaseRef)
  .prop('email', email)
  .required(['email', 'role', 'school', 'district'])

const resendInvite = Schema()
  .prop('email', email)
  .required(['email'])

const inviteDistrictAdmin = Schema()
  .prop('email', email)
  .prop('district', firebaseRef)
  .required(['email', 'district'])

const setDistrict = Schema()
  .prop('district', firebaseRef)
  .required(['district'])

const setActive = Schema()
  .prop('assignedActivity', uuid)
  .prop('lesson', uuid)
  .prop('module', firebaseRef)
  .prop('user', firebaseRef)
  .required(['assignedActivity', 'lesson', 'module'])

const recordLogin = Schema()
  .prop('uid', firebaseRef)
  .prop('classId', firebaseRef)
  .required(['uid', 'classId'])

const recordVideoStart = Schema()
  .prop('school', firebaseRef)
  .prop('class', firebaseRef)
  .prop('uid', firebaseRef)
  .prop('activity', uuid)
  .required(['school', 'class', 'uid', 'activity'])

const recordVideoEnd = Schema()
  .prop('uid', firebaseRef)
  .prop('startTime', date)
  .required(['uid', 'startTime'])

const emailIndependentTeachersTest = Schema()
  .prop('emails', Schema('array').items(email))
  .required(['emails'])

const loginIntegrationAccount = Schema()
  .prop('integration', Schema('string'))
  .required(['integration'])

exports.default = User
exports.createStudentWithStateId = validate(createStudentWithStateId)
exports.createStudentWithEmail = validate(createStudentWithEmail)
exports.setAssignedLessonIndex = validate(setAssignedLessonIndex)
exports.inviteDistrictAdmin = validate(inviteDistrictAdmin)
exports.setInsecurePassword = validate(setInsecurePassword)
exports.signInWithPassword = validate(signInWithPassword)
exports.setCurrentSchool = validate(setCurrentSchool)
exports.removeFromSchool = validate(removeFromSchool)
exports.setTermsVersion = validate(setTermsVersion)
exports.createStudents = validate(createStudents)
exports.createStudent = validate(createStudent)
exports.resendInvite = validate(resendInvite)
exports.assignLesson = validate(assignLesson)
exports.addToSchool = validate(addToSchool)
exports.setDistrict = validate(setDistrict)
exports.archiveUser = validate(archiveUser)
exports.recordLogin = validate(recordLogin)
exports.inviteUser = validate(inviteUser)
exports.setActive = validate(setActive)
exports.setRoles = validate(setRoles)
exports.editUser = validate(editUser)
exports.setNav = validate(setNav)
exports.signUp = validate(signUp)
exports.recordVideoStart = validate(recordVideoStart)
exports.recordVideoEnd = validate(recordVideoEnd)
exports.emailIndependentTeachersTest = validate(emailIndependentTeachersTest)
exports.loginIntegrationAccount = validate(loginIntegrationAccount)
